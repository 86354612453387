@import "styles/variables";

.base-button {
  padding: 12px 24px;
  border-radius: 12px;
  background-color: $light-grey;
  border: none;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-align: center;
  line-height: 125%;
  box-sizing: border-box;
}

.base-button:hover,
.base-button:focus,
.base-button:active,
.base-button.active {
  background-color: $black;
  color: $white;
  cursor: pointer;
}

.base-button-fixed {
  @extend .base-button;
  position: fixed;
  bottom: 32px;
  left: calc(50% - 60px);
}

.button {
  @extend .base-button;
}

.button-large {
  @extend .button;
  padding: 16px 24px;
  border-radius: 24px;
  font-size: 16px;
  background-color: $light-grey;
}

.button-action {
  @extend .base-button-fixed;
  border: 1px solid $apple-light-grey;
}

.button-action-small {
  @extend .button-action;
  padding: 8px 8px;
  font-size: 14px;
}

.script-button-action {
  @extend .button-action;
  position: absolute;
  bottom: 36px;
  margin: 0 auto;
  left: calc(50% + 36px);
  transform: translateX(-60%);
  font-size: 18px;
}

.button-series-select {
  @extend .base-button;
  margin-right: 16px;
  font-size: 12px;
}

.button-dialog-turn-navigation {
  @extend .button;
  margin-right: 16px;
  padding: 8px 16px;
  font-size: 24px;
}

.button-word-link-definition {
  @extend .button;
  margin: 0px 8px;
  padding: 8px 16px;
}

.button-word-link-delete {
  @extend.button;
  margin-right: 16px;
}

.button-sign-in,
.button-sign-up {
  @extend .base-button;
  width: 300px;
  padding: 12px;
  border: none;
  border-radius: 8px;
  height: 48px;
  margin: 4px 0px;
}

.button-upload-location {
  @extend .base-button;
  margin-right: 16px;
  background-color: $dropbox-grey;
}