.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  padding: 32px;
  width: 40%;
  height: 80%;
  border-radius: 24px;
  // overflow-y: auto;
  align-items: flex-start;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
