@import "styles/variables.scss";

.input-field-section {
    max-width: 15vw;
}

.section-text-search {

    .input-text-search {
        border-radius: 16px;
        padding: 16px 16px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $dropbox-grey;
        background-color: $light-grey;
    }

    .button-text-search {
        background-color: black;
        color: white;
    }
}