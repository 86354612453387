.validation-table th,
.validation-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    width: 6vw;
    font-size: 0.8vw;
    font-weight: 500;
}

.validation-table {
    margin: 8px 0px;
}