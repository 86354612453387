@import "styles/variables.scss";

.custom-table-layout {
    display: table;
    border-collapse: collapse;

    thead {
        line-height: 2;
    }

    th {
        width: 100%;
    }
}

.custom-table-textarea {
    box-sizing: border-box;
    align-items: center;
    vertical-align: middle;
    background-color: $border-grey;
}

.custom-table-button {
    cursor: pointer;
    aspect-ratio: 1/1;
    width: 100%;
    box-sizing: border-box;
    border: none;
    padding: 12px;
    border-radius: 4px;
    background-color: $border-grey;
    align-items: center;
}

.custom-table-button:hover {
    background-color: black;
}

.audio-create-button,
.audio-play-button {
    @extend .custom-table-button;
}