@import "styles/variables.scss";

.tab-edit-episode-word-cards {
  .word-card-info-item {
    display: grid;
    grid-template-columns: 5fr 2fr;
    padding: 4px 0px;
    column-gap: 16px;

    .component-word-card-images {
      display: flex;
      overflow-x: auto;
      scrollbar-width: none;
      gap: 8px;

      .word-card-image {
        width: calc(25% - 10px);
        aspect-ratio: 5 / 6;
        border-radius: 4px;
        cursor: pointer;
        border: 2px solid $light-grey;
        filter: brightness(40%);
      }

      .empty-word-card-image {
        @extend .word-card-image;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $light-grey;
        filter: brightness(100%);
      }

      .empty-word-card-images {
        width: calc(100%);
        background-color: rgb(235, 235, 235);

        border: none;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .word-card-image.selected {
        border: 2px solid red;
        opacity: 1;
        filter: brightness(100%);
      }
    }

    .component-word-card-detail {
      gap: 4px;
      font-size: 10px;

      .detail-item {
        align-items: top;
        gap: 8px;
        display: flex;
        margin-bottom: 4px;
        width: 100%;

        .label {
          background-color: $light-grey;
          border-radius: 4px;
          padding: 4px;
          width: 56px;
          text-align: center;
          align-items: top;
          height: 100%;
          flex-shrink: 0;
          font-size: 14px;
        }

        .item {
          display: flex;
          flex-direction: row;
          gap: 4px;
          line-height: 150%;
          flex-grow: 1;
          font-size: 14px;
        }
      }

      .prompt-container {
        width: 100%;

        .prompt-input {
          height: 100px;
          width: 100%;
          font-size: 14px;
        }
      }

      .definitions-container {
        gap: 8px;

        .definition-container {
          padding: 4px;
          border-radius: 4px;
          cursor: pointer;
          align-items: center;
          text-align: center;
          border: none;
          gap: 8px;
          font-size: 14px;
        }

        .definition-container.selected {
          background-color: $black;
          color: white;
        }

        .definition-container.disabled {}
      }

      .create-image-button {
        font-size: 14px;
      }
    }
  }
}