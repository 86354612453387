.non-editable-cell {
  background-color: #eff2f6;
  border-color: #e3e6ea;
  color: #9ba3b3;
  align-items: center;
}

.editable-cell {
  background-color: white;
  border-color: none;
  color: #007aff;
  cursor: text;
  align-items: center;
}

.editable-cell.multiline {
  white-space: pre-wrap !important;
  line-height: 120% !important;
  word-break: break-word !important;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ag-header-cell-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.editable-cell:hover {
  cursor: text;
}

.ag-cell-inline-editing {
  cursor: text;
}

.base-button-ag-grid {
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 8px;
  cursor: pointer;
}

.update-button {
  @extend .base-button-ag-grid;
}

.delete-button {
  @extend .base-button-ag-grid;
  background-color: $apple-red;
}

.restore-button {
  @extend .base-button-ag-grid;
  background-color: $apple-green;
}

.hard-delete-button {
  @extend .base-button-ag-grid;
  background-color: $black;
  color: white;
}

.reply-button {
  @extend .base-button-ag-grid;
  background-color: $apple-dark-blue;
}

.publish-button {
  @extend .base-button-ag-grid;
  background-color: $apple-green;
}

.validate-button {
  @extend .base-button-ag-grid;
  background-color: $black;
  color: white;
}