@import "styles/variables";
@import "styles/global";
@import "styles/layouts";

.next {
  width: calc((100% - #{$sidebar-width}) * 0.95);
  background-color: $primary-color;
  color: white;
  height: 48px;
  border-radius: 12px;
  position: absolute;
  margin: auto;
  border: None;
  cursor: pointer;
  bottom: 24px;
}

.audio-button {
  background-color: $apple-dark-blue;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  margin-right: 16px;
  cursor: pointer;

  &.isPlaying {
    background-color: $dark-grey;
  }
}

.audio-icon {
  color: $white;

  &.isPlaying {
    color: $light-grey;
  }
}

.button-action {
  background-color: $light-grey;
  color: $dark-grey;
  border-radius: 24px;
  padding: 8px 16px;
  cursor: pointer;
}

.component-is-loading,
.component-is-error {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
}

.work-in-progress-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);

  .container {
    padding: 64px 24px;
    color: black;
    background-color: white;
    border-radius: 16px;

    .text {
      margin-top: 16px;
    }
  }
}
