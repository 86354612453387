@import "styles/variables";
@import "styles/global";
@import "styles/buttons";
@import "styles/layouts";
@import "popup";

.base-modal {
  width: 100%;
  height: calc(width * 1.2);
  display: flex;
  flex-direction: column;

  .section-modal-content {
    overflow-y: auto;
    margin: 32px 0px;
    flex: 1;
  }

  .button-modal-action {
    background-color: $apple-light-grey;
    color: $white;
    width: 100%;
    // margin: 0 auto;
    padding: 12px 32px;
    font-size: 16px;
    border-radius: 24px;
    cursor: pointer;
    border: none;
    margin-top: 16px;
  }

  .button-modal-action:hover {
    background-color: $black;
  }

  .input-field-section {
    margin-bottom: 16px;

    .input-label {
      color: $black-10;
      margin-bottom: 8px;
      width: 300px;
      font-weight: 500;
    }

    .input-field {
      border: 1px solid $grey;
      border-radius: 24px;
      background-color: $light-grey-50;
      height: 48px;
      padding: 0 16px;
      margin-bottom: 16px;
      font-size: 16px;
      color: $dark-grey;
      max-width: 320px;
    }

    .input-field.large {
      max-width: 100%;
      height: 80px;
      vertical-align: top;
    }
  }
}

.modal-create-new-character,
.modal-create-new-word,
.modal-create-new-episode,
.modal-create-new-series {
  height: 100%;
  @extend .base-modal;
}

.modal-create-new-word {
  .input-field-section {
    .input-label {
      color: $black;
      margin-bottom: 8px;
    }

    .input-field {
      border: 1px solid $dark-grey;
      border-radius: 5px;
      height: 48px;
      padding: 0 16px;
      margin-bottom: 16px;
      font-size: 16px;
      color: $dark-grey;
      max-width: 320px;
      vertical-align: top;
    }

    .input-field.large {
      max-width: 100%;
    }
  }
}