@import "styles/variables";

.modal {
  padding: 24px;
}

.max-height {
  height: 100%;
}

.max-width {
  width: 100%;
}

.margin-16 {
  margin: 16px;
}

.margin-24 {
  margin: 24px;
}

.margin-36 {
  margin: 32px;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-right-16 {
  margin-right: 16px;
}

.margin-left-16 {
  margin-left: 16px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-right-24 {
  margin-right: 24px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-auto-all {
  margin: auto;
}

.margin-auto-hr {
  margin-left: auto;
  margin-right: auto;
}

.margin-auto-vr {
  margin-top: auto;
  margin-bottom: auto;
}

.gap-16 {
  gap: 16px;
}

.gap-8 {
  gap: 8px;
}

.gap-32 {
  gap: 32px;
}

.gap-16 {
  gap: 16px;
}

// Tabs : Add padding to the bottom of the section-tabs
.section-tabs {
  padding-bottom: 64px;
}