@import "styles/variables.scss";


.create-script-step-1 {

  .raw-script-textarea {
    width: calc(100% - 64px);
    height: 50vh;
    background-color: $dropbox-grey;
    border-radius: 16px;
    padding: 32px;
    box-sizing: content-box;
    line-height: 1.8;
  }
}

.navigation-buttons {

  .previous-button,
  .next-button {
    background-color: $primary-color;
    color: white;
    border-radius: 5px;
    padding: 8px;
    margin: 8px;
    cursor: pointer;
  }

  .next-button {
    background-color: blue;
  }

  .button-label {
    margin: 0 8px;
  }
}

.submit-button {
  height: 52px;
  position: absolute;
  bottom: 36px;
}

.create-script {
  padding-bottom: 64px;
}

.create-script-navigation-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  // border-top-left-radius: 16px;
  // border-top-right-radius: 16px;
  width: 100%;
  height: 6vh;
  background-color: rgba(39, 34, 34);
  // transform: translateY(80%);
  transition: transform 0.1s ease-out, background-color 0.1s ease-out;
  padding: 4px 32px;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .drawer-icon {
    // position: relative;
    // top: -36px;
    // margin: 0 auto;
    // font-size: 20px;
    // opacity: 0.3;
    // display: flex;
    // justify-content: center;
    // color: black;
    visibility: hidden;
  }

  .buttons-container {
    // position: relative;
    // bottom: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    height: 100%;

    .button {
      width: 10vw;
    }

    .button.hidden {
      position: relative;
      display: none;
    }

    .button-next,
    .button-submit {
      background-color: $vocatale-green;
      color: black;
    }

    .button:hover {
      background-color: white;
      filter: brightness(100%);
      color: black;
    }

    .button-back {
      filter: brightness(80%);
    }
  }

  .script-info-container {
    color: white;
    font-style: italic;
    position: relative;
    align-items: center;
  }

  .speech-style-selector {

    select {
      background-color: $dropbox-grey;
      color: black;
      border: none;
      border-radius: 5px;
      padding: 8px;
      margin: 8px;
      cursor: pointer;
      font-weight: bold;
    }
  }

}

.create-script-navigation-bar.visible {
  transform: translateY(0);
  background-color: rgba(39, 34, 34, 1);

  .drawer-icon {
    visibility: hidden;
  }
}