@import "styles/variables.scss";

.page-edit-script {
    .section-word-link {
        padding: 32px 0px;

        .dialog-word-link-section {
            padding-top: 32px;
        }

        .container-word-text {
            width: 200px;
            font-size: 20px;
            line-height: 150%;
        }
    }
}

// For Audio Buttons
.dialog-audio-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    padding: 0px;
    margin-bottom: 1px;

    .button-audio-play {
        background-color: black;
        height: 48px;
        width: 48px;
        cursor: pointer;
        border-radius: 5px;
        justify-content: center;
        margin: 0 auto;
    }

    .button-audio-refresh {
        background-color: blue;
        height: 50px;
        width: 50px;
        cursor: pointer;
        border-radius: 5px;
        margin: 0 auto;
    }
}

.dialog-turn-navigator {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 12px;
}


.episode-play-mode {
    height: 100%;
    align-items: center;
    overflow-x: auto;
    display: flex;
}