@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");
@import "styles/variables";
@import "styles/agGrid";
@import "styles/layouts";
@import "styles/popup";
@import "styles/buttons";
@import "styles/colors";

@layer base {

  html {
    font-family: $font-family;
  }

  .h1 {
    font-size: 2em;
    font-weight: 900;
    margin-block: 0.65em;
    line-height: 1em;
  }

  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 6 97% 71%;
    /* #fd7f6f */
    --chart-2: 207 50% 66%;
    /* #7eb0d5 */
    --chart-3: 87 71% 63%;
    /* #b2e061 */
    --chart-4: 292 41% 62%;
    /* #bd7ebe */
    --chart-5: 33 100% 68%;
    /* #ffb55a */
    --chart-6: 55 100% 70%;
    /* #ffee65 */
    --chart-7: 249 29% 79%;
    /* #beb9db */
    --chart-8: 340 92% 90%;
    /* #fdcce5 */
    --chart-9: 167 42% 68%;
    /* #8bd3c7 */
    --chart-10: 0 0% 100%;
    /* #ffffff (white, kept from original) */
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}




// Body
body,
.root {
  padding: 0px;
  margin: 0px;
  font-family: $font-family;
}

// Page Layout

.page-layout {
  max-width: 100%;
  min-width: 100%;
  height: 100vh;
  display: flex;
  max-height: 100%;
}

.page {
  padding: 24px;
  max-width: 100%;
}

.width-full {
  width: 100vw;
}

.height-full {
  height: 100vh;
}

.content-layout {
  width: 100%;
  height: 100%;
  // padding: 24px 0px 24px 24px;
  overflow: auto;
}

//Overflow

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

// Flex

.display-flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-left {
  justify-content: flex-start;
}

.justify-right {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

// Text Sizes
h1 {
  font-size: 2em;
  font-weight: 900;
  margin-block: 0.65em;
  line-height: 1em;
}

h2 {
  font-size: 1.5em;
  font-weight: 800;
  margin-block: 0.55em;
  line-height: 1em;
}

h3 {
  font-size: 1.17em;
  font-weight: 700;
  line-height: 0.45em;
  line-height: 1em;
}

h4 {
  font-size: 1em;
  font-weight: 700;
  margin-block: 0.35em;
  line-height: 1em;
}

h5 {
  font-size: 0.83em;
  font-weight: 500;
  margin-block: 0.25em;
  line-height: 1em;
}

span,
div,
input,
textarea,
button {
  font-size: 2vh;
}

// Text Alignment

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// Text Decorations

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: bold;
}

.text-grey {
  color: grey;
}

.cursor-pointer {
  cursor: pointer;
}

//alignment

.align-center {
  align-items: center;
}

.align-left {
  align-items: left;
}

.align-right {
  align-items: right;
}