@import "styles/variables.scss";

.select-series {

    .series-poster {
        filter: brightness(40%);
        cursor: pointer;

        img {
            width: 16vw;
            aspect-ratio: 4/5;
            border-radius: 24px;
            border: 2px solid $border-grey;
            box-sizing: border-box;
        }
    }

    .series-poster.selected {
        filter: brightness(100%);

        img {
            border: 2px solid black;
            box-sizing: content-box;
        }
    }
}

.select-episode {

    .episode-button.selected {
        background-color: black;
        color: white;
    }

}