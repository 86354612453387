$primary-color: #302b3f;
$on-primary-color: #ffffff;
$secondary-color: #32cd32;
$background-color: #ffffff;
$on-background-color: #e6e6e7;
$error-color: #ff0000;
$success-color: #008000;
$warning-color: #ffa500;
$info-color: #0000ff;
$vocatale-green : #6BF3B1;
$vocatale-blue: #65E9F2;


// Grey

$dark-grey: #2c2c4e;
$light-grey: #e8e9eb;
$dropbox-grey : #F4EFE8;


// Custom Table
$border-grey : #e8e9eb;
$fill-grey : #f5f5f5;


// Apple colors
$apple-dark-blue: #0a83ff;
$apple-light-grey: #c1c1c1;
$apple-green: #4cd865;
$apple-light-blue: #dfecff;
$apple-red: #ff3c2f;

$black: #000000;
$white: #ffffff;
$black-10: rgba(0, 0, 0, 0.9);

$grey: #9d9d9f;
$grey-10: rgba(131, 131, 131, 0.9);

$light-grey: #f5f5f5;
$light-grey-10: rgba(245, 245, 245, 0.9);
$light-grey-50: rgba(245, 245, 245, 0.5);

$font-family: "Pretendard", sans-serif;
$font-size: 16px;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$spacing-small: 8px;
$spacing-medium: 16px;
$spacing-large: 24px;
$spacing-x-large: 32px;

$box-padding: 32px;

$sidebar-width: 240px;