@import "styles/variables.scss";

.component-dropzone {
  padding: 32px 0px;
  text-align: center;
  width: 100%;

  .dropzone-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px dashed grey;
    border-radius: 5px;
    cursor: pointer;
    transition: border 0.3s ease;
    width: 90%;
    min-height: 30vh;
    max-height: 60vh;
    width: calc(100% - 32px);
    overflow-y: scroll;
    background-color: $dropbox-grey;

    &.is-drag-over {
      border-color: black;
      background-color: black;
    }

    .dropzone-icon {
      font-size: 40px;
      color: grey;
    }

    .dropzone-text {
      font-size: 16px;
      color: grey;
      margin-top: 10px;
    }

    &.active {
      background-color: grey;
    }
  }

  .dropzone-label {
    font-size: 1rem;
    color: grey;
  }


  .file-list {
    .file-list-item-container {
      display: grid;
      grid-template-columns: 1fr 4fr 1fr 1fr;
      text-align: left;
      align-items: center;

      .file-preview {
        border-radius: 16px;
        height: 40px;
        width: 40px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .file-preview-image {
          height: 40px;
          width: 40px;
          object-fit: cover;
          object-position: center;
        }
      }

      .file-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .file-size {
        text-align: right;
      }

      .file-delete-button {
        background-color: $apple-dark-blue;
        color: white;
        border: none;
        border-radius: 8px;
        padding: 5px 10px;
        cursor: pointer;
      }
    }
  }
}