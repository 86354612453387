@import "styles/variables";
@import "styles/global";

.sidebar {
  max-width: 64px;
  max-height: 100%;
  height: 100%;
  background-color: #f9f9f9;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  border-right: 1px solid #dde0e6;
  transition: max-width 0.5s, width 0.5s;

  .sidebar-layout {
    width: $sidebar-width;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .logo-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo {
      width: 32px;
      height: 32px;
      margin-bottom: 8px;
    }

    .logo-text {
      visibility: hidden;
      display: none;
    }
  }

  .menu-section {
    width: 100%;
    padding: 12px;
  }

  .menu-items {
    overflow-y: auto;
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  .menu-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f1f1f3;
    }

    .menu-content {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px;

      .icon {
        width: 24px;
        height: 24px;
      }

      .text {
        display: none;
      }
    }
  }

  .active {
    background-color: #f1f1f3;
  }

  .logout {
    position: absolute;
    bottom: 0;
    width: calc(100% - 32px);
    text-align: center;
    padding: 16px;
  }

  .logo-text,
  .menu-item .text,
  .heading {
    display: none;
  }

  &:hover {
    max-width: 15%;
    align-items: flex-start;

    .logo-section {
      .logo-text {
        display: inline-block;
        line-height: 0;
        justify-content: flex-start;
      }
    }

    .menu-item {
      .menu-content {
        .text {
          line-height: 0;
          display: inline-block;
          margin-left: 16px;
        }
      }
    }
  }
}