@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import "styles/variables.scss";

$dialog-box-background : #152535;
$vocatale-color : #6BF3B1;
$word-tag-background : #f0f0f0;
$button-next-dialog-turn-background : #DDDDDD;

.episode-play {
    font-family: 'Lexend';

    .background-container {
        height: 100%;
        width: 100%;
        width: 18vw;
        aspect-ratio: 9/18;
        border-radius: 36px;
        border: 5px solid black;
        align-items: center;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-sizing: border-box;
        background-color: grey;
    }

    .character-container {
        height: 100%;
        width: 100%;
        padding: 16px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-sizing: border-box;
    }

    .button-next-dialog-turn {
        background-color: $button-next-dialog-turn-background;
        color: black;
    }

    .button-next-dialog-turn:hover {
        background-color: $vocatale-color;
    }
}

.dialog-box {
    max-width: 100%;

    .dialog-section {
        max-width: 100%;
        // padding: 32px 24px 24px;
        border: 2px solid black;
        border-radius: 16px;
        background: rgba(21, 37, 53, 0.7);
        color: white;
    }

    .target-script-underline {
        text-decoration: underline;
        text-decoration-style: dotted;
        text-decoration-color: white;
        text-decoration-thickness: 1.5px;
    }

    .target-script-highlight,
    .base-script-highlight {
        // font-weight: 600;
        color: $vocatale-color;
        // font-size: 1em;
    }

    .tags {
        z-index: 9;
        color: black;
        padding: 0px 24px;

        .character-tag,
        .word-tag {
            // font-size: 1vw;
            min-width: 40px;
            padding: 4px 8px;
            border-radius: 8px;
            position: relative;
            top: 20px;
            text-align: center;
            align-items: center;
        }

        .character-tag {
            background-color: $vocatale-color;
            font-weight: 600;
        }
    }

    // .dialog-text {
    //     line-height: 150%;
    //     color: white;
    //     font-weight: 500;
    //     font-size: 1vw;
    // }

    .hint-text {
        margin-top: 16px;
        // font-size: 0.8vw;
        font-weight: 200;
    }

    .buttons {
        margin-top: 16px;

        .audio-play-button,
        .hint-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2vw;
            height: 2vw;
            border-radius: 50%;
            border: none;
            background: rgba(255, 255, 255, 0.3);
            cursor: pointer;
        }

        .audio-play-button:hover,
        .hint-button:hover {
            background-color: #f0f0f0;
            color: black;
        }

        .audio-play-button.active,
        .hint-button.active {
            background-color: $vocatale-color;
            color: black;
        }
    }
}

.multiple-choices {
    display: grid;
    margin-top: 24px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5em;
    margin: 0 auto;
    width: 100%;

    .multiple-choice {
        text-align: center;
        padding: 16px 12px;
        align-items: center;
        background-color: white;
        color: black;
        border-radius: 8px;
        cursor: pointer;
    }

    .multiple-choice.selected,
    .multiple-choice:hover {
        background-color: $vocatale-color;
        color: black;
    }

    .multiple-choice .choice-text {
        // font-size: 0.9vw;
        font-weight: 500;
    }
}

.progress-bar {
    padding: 8px;
    width: 100%;
    border-radius: 48px;
    background: rgba(255, 255, 255, 0.2);

    .progress-bar-inner {
        background-color: $vocatale-color;
    }
}

.next-button {
    padding: 12px 16px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
}

.next-button:hover {
    background-color: $vocatale-color;
    color: black;
}