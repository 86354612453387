.component-audio-setting-sliders {
  .slider-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    margin: 16px 0px;
    max-width: 500px;
    border-radius: 24px;

    .item-header {
      font-size: 1.2rem;
      font-weight: bold;
    }

    .item-label {
      font-size: 0.8rem;
    }

    .slider {
      color: black;
      
    }
  }
}