@import "styles/variables.scss";

.page-inquiry {
  display: flex;
  flex-direction: column;

  .messages-container {
    flex-grow: 1;
    overflow-y: auto;
    padding: 8px;
    border: 1px solid #ccc;
    margin-bottom: 16px;
    max-width: 60%;
  }

  .message-container {
    max-width: 100%;
    line-height: 200%;
    margin-bottom: 16px;
    overflow-y: auto;
    display: flex;

    .message-container.admin {
      @extend .message-container;
      justify-content: flex-end;
      text-align: right;

      .message-content {
        background-color: $apple-light-blue;
        padding: 24px;
        border-radius: 24px;
        justify-content: flex-end;
      }
    }

    .message-container.user {
      @extend .message-container;
      justify-content: flex-start;
      text-align: left;
    }
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin-top: 16px;
    max-width: 60%;

    .input {
      width: 100%;
      height: 100px;
    }
  }

  button {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }
}
