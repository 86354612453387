@import "styles/variables.scss";

.page-sign-in,
.page-sign-up {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .container-sign-in,
  .container-sign-up {
    max-width: 300px;
    width: 100%;
    margin: auto auto;

    .text-input-box {
      width: calc(100% - 24px);
      padding: 12px;
      border-radius: 8px;
      border: 1px solid $apple-light-grey;
    }

    .text-input-box:focus {
      border: 1px solid black;
    }
    input:focus {
      color: black;
    }
  }
}
